import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Box, Button, Chip, FormControl, FormHelperText, Tab, Tabs, TextField } from '@mui/material';
import { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';
import { LexicalEditor, RangeSelection } from 'lexical';

import './index.css';
import { a11yProps } from '../../../navigation/TabPanel';

export function StatusBox({
  readOnly,
  editor,
  hideStatus,
  defaultKeyTermNameLists,
  submitUpdateAnnotation,
  originalRangeRect,
  originalSelectionRects
}: {
  readOnly: boolean;
  editor: LexicalEditor;
  hideStatus: () => void;
  defaultKeyTermNameLists: string[][];
  submitUpdateAnnotation: (
    keyTermNameLists: string[][]
  ) => void;
  originalRangeRect?: DOMRect;
  originalSelectionRects?: ClientRect[];
}) {
  const [keyTermNameLists, setKeyTermNameLists] = useState<string[][]>(defaultKeyTermNameLists);
  const [selectedHighlightIndex, setSelectedHighlightIndex] = useState<number>(0);
  const { documentKeyTermsService } = useKeyTermGroupState();

  const boxRef = useRef<HTMLDivElement>(null);

  const selectionState = useMemo(
    () => ({
      container: document.createElement('div'),
      elements: [],
    }),
    [],
  );

  const selectionRef = useRef<RangeSelection | null>(null);

  const currentKeyTermNames = useMemo(() =>
    documentKeyTermsService.keyTerms?.map(kt => kt.termName),
    [documentKeyTermsService.keyTerms]
  );

  const lockedKeyTerms = useMemo(() =>
    documentKeyTermsService.keyTerms?.filter(kt => kt.locked)
      .map(kt => kt.termName) || [],
    [documentKeyTermsService.keyTerms]
  );

  const updateLocation = useCallback(() => {
    if (originalRangeRect && originalSelectionRects) {
      const boxElem = boxRef.current;
      if (boxElem !== null) {
        const { left, bottom, width } = originalRangeRect;
        let correctedLeft = originalSelectionRects.length === 1 ? left + width / 2 - 125 : left - 125;
        if (correctedLeft < 10) {
          correctedLeft = 10;
        }
        boxElem.style.left = `${correctedLeft}px`;
        boxElem.style.top = `${bottom +
          20 +
          (window.pageYOffset || document.documentElement.scrollTop)
          }px`;
        const selectionRectsLength = originalSelectionRects.length;
        const { container } = selectionState;
        const elements: Array<HTMLSpanElement> = selectionState.elements;
        const elementsLength = elements.length;

        for (let i = 0; i < selectionRectsLength; i++) {
          const selectionRect = originalSelectionRects[i];
          let elem: HTMLSpanElement = elements[i];
          if (elem === undefined) {
            elem = document.createElement('span');
            elements[i] = elem;
            container.appendChild(elem);
          }
          const color = '255, 212, 0';
          const style = `position:absolute;top:${selectionRect.top +
            (window.pageYOffset || document.documentElement.scrollTop)
            }px;left:${selectionRect.left}px;height:${selectionRect.height
            }px;width:${selectionRect.width
            }px;background-color:rgba(${color}, 0.3);pointer-events:none;z-index:5;`;
          elem.style.cssText = style;
        }
        for (let i = elementsLength - 1; i >= selectionRectsLength; i--) {
          const elem = elements[i];
          container.removeChild(elem);
          elements.pop();
        }
      }
    }

  }, [editor, selectionState, originalRangeRect, originalSelectionRects]);

  useLayoutEffect(() => {
    updateLocation();
    const container = selectionState.container;
    const body = document.body;
    if (body !== null) {
      body.appendChild(container);
      return () => {
        body.removeChild(container);
      };
    }
  }, [selectionState.container, updateLocation]);

  useEffect(() => {
    window.addEventListener('resize', updateLocation);
    return () => {
      window.removeEventListener('resize', updateLocation);
    };
  }, [updateLocation]);

  const submitUpdate = () => {
    submitUpdateAnnotation(keyTermNameLists);
    selectionRef.current = null;
  };

  return (
    <div className="CommentPlugin_CommentInputBox" ref={boxRef}>
      {defaultKeyTermNameLists.length > 1 && (
        <Box m={1}>
          <Tabs
            value={selectedHighlightIndex}
            onChange={(event: React.SyntheticEvent, newValue: number) => setSelectedHighlightIndex(newValue)}
          >
            {defaultKeyTermNameLists.map((dktns, i) => (
              <Tab
                label={`#${i + 1}`}
                {...a11yProps(i)}
              />
            ))}
          </Tabs>
        </Box>
      )}
      <FormControl sx={{ m: 1, width: "95%" }}>
        <Autocomplete
          contentEditable={!readOnly}
          size='small'
          multiple
          data-testid="key-terms-autocomplete"
          options={currentKeyTermNames || []}
          getOptionLabel={(option) => option}
          getOptionDisabled={(option) => lockedKeyTerms.includes(option)}
          value={keyTermNameLists[selectedHighlightIndex]}
          onChange={(event, newValue) => {
            let newKeyTermNameLists = [...keyTermNameLists];
            newKeyTermNameLists[selectedHighlightIndex] = newValue;
            setKeyTermNameLists(newKeyTermNameLists);
          }}
          freeSolo
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Key Terms"
              data-testid="key-terms-input"
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                disabled={lockedKeyTerms.includes(option)}
              />
            ))
          }
        />
        <FormHelperText>
          Click enter to add a new key term or select 1 or more previous terms.
        </FormHelperText>
      </FormControl>
      <Box m={1}>
        <Button
          data-testid="update-comment-button"
          size='small'
          color="primary"
          onClick={submitUpdate}
        >
          Update
        </Button>
        <Button
          data-testid="cancel-comment-button"
          size='small'
          onClick={hideStatus}
        >
          Cancel
        </Button>
      </Box>
    </div>
  );
}
