import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, ButtonGroup, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useWidgetState } from "../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../containers/WidgetWrapper/states";
import { ArrowDropDown, Bolt, Settings } from "@mui/icons-material";
import AssistantIcon from '@mui/icons-material/Assistant';
import GroupManagerModal from "./GroupManagerModal";
import { GenerateSummariesDialog } from "./GenerateSummariesDialog";
import { useSnackbar } from "notistack";
import { useKeyTermGroupState } from "../../containers/TakerDocumentState/KeyTermGroupState";
import { useLocalStorage } from "@uidotdev/usehooks";

const MainToolbar = ({
    readOnly
}: {
    readOnly: boolean;
}) => {
    const {
        taker,
        takerDocumentUploads,
        takerDocumentId
    } = useTakerState();
    const { getState, mutateState } = useWidgetState()
    const { enqueueSnackbar } = useSnackbar();
    const [selectedTtduId, saveSelectedTtduId] = useLocalStorage<string | null>(`${taker?.id}-ttduId`, null);

    const groupManagerOpen = getState<KeyTermsState>().groupManagerOpen;
    const panelViewMode = getState<KeyTermsState>().panelViewMode;

    const [aiSummaryBtnOpen, setAiSummaryBtnOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const { takerDocumentUpload } = useKeyTermGroupState();
    const groupButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (!takerDocumentUpload) {
            groupButtonRef?.current?.focus();
        }
    }, [takerDocumentUpload]);


    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setAiSummaryBtnOpen(false);
    };

    const selectedPanels = useMemo(() => {
        if (panelViewMode === 0) {
            return [0, 1];
        } else if (panelViewMode === 1) {
            return [0];
        } else if (panelViewMode === 2) {
            return [1];
        }
    }, [panelViewMode]);

    const sortedTakerDocumentUploads = useMemo(() =>
        [...(takerDocumentUploads || [])].sort((a, b) => b.createdAt - a.createdAt),
        [takerDocumentUploads]
    );

    const notifySummary = () => {
        enqueueSnackbar("Please create and select a group before using summaries.", {
            key: 'file-item-upload',
            preventDuplicate: true,
            variant: 'info',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
    }
    const [openComingSoon, setOpenComingSoon] = useState(false);

    return (
        <Box
            sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                paddingLeft: 1,
                border: "1px solid #ddd",
                borderRadius: 1,
                backgroundColor: "rgba(221,221,221,0.25)",
            }}
        >
            <Grid
                container
                justifyItems="center"
                alignItems="center"
                paddingRight={1}
                paddingTop={0.5}
                paddingBottom={0.5}
            >
                <Grid
                    item
                    container
                    xs={4}
                    justifyContent="start"
                    justifyItems="center"
                >
                    <Grid item alignItems="center">
                        <ButtonGroup
                            variant="contained"
                            ref={anchorRef}
                            aria-label="Button group with a nested menu"
                        >
                            <Button
                                data-testid="generate-summaries"
                                onClick={() => {
                                    if (!!takerDocumentUpload?.id) {
                                        mutateState<KeyTermsState>({
                                            showAiSummaries: true
                                        });
                                    } else {
                                        notifySummary();
                                    }
                                }}
                                startIcon={<Bolt />}
                            >
                                Summaries
                            </Button>
                            <Button
                                data-testid="generate-summaries-dropdown"
                                aria-controls={aiSummaryBtnOpen ? 'split-button-menu' : undefined}
                                aria-expanded={aiSummaryBtnOpen ? 'true' : undefined}
                                aria-haspopup="menu"
                                onClick={() => {
                                    if (!!takerDocumentUpload?.id) {
                                        setAiSummaryBtnOpen((prevOpen) => !prevOpen);
                                    } else {
                                        notifySummary();
                                    }
                                }}
                            >
                                <ArrowDropDown />
                            </Button>
                        </ButtonGroup>
                        <Popper
                            sx={{
                                zIndex: 1,
                            }}
                            open={aiSummaryBtnOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList id="split-button-menu" autoFocusItem>
                                                {["generate new summaries"].map((option, index) => (
                                                    <MenuItem
                                                        data-testid="generate-summaries-dropdown-item"
                                                        key={option}
                                                        disabled={index === 2}
                                                        onClick={(event) => {
                                                            if (index === 0) {
                                                                mutateState<KeyTermsState>({
                                                                    showGenerationDialog: true
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                    <Grid item alignItems="center">
                        <IconButton
                            onClick={() => setOpenComingSoon(true)}
                        >
                            <AssistantIcon />
                        </IconButton>
                        <Dialog
                            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                            maxWidth="xs"
                            open={openComingSoon}
                        >
                            <DialogTitle>Contract Helper - Coming Soon</DialogTitle>
                            <DialogContent>This feature is currently in development. The Contract Helper is an AI aid that gives the user helpful tips based on the uploaded files. </DialogContent>
                            <DialogActions>
                                <Button
                                    autoFocus
                                    onClick={() => {
                                        setOpenComingSoon(false);
                                    }}
                                >
                                    Okay
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    xs={4}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        {(sortedTakerDocumentUploads && sortedTakerDocumentUploads.length > 0) ? (
                            <FormControl 
                                data-testid="group-select"
                                sx={{ minWidth: 80 }}
                            >
                                <Select
                                    value={selectedTtduId || undefined}
                                    size="small"
                                    onChange={(event: SelectChangeEvent) => {
                                        mutateState<KeyTermsState>({
                                            targetFileUploadItemId: undefined
                                        });
                                        saveSelectedTtduId(event.target.value);
                                    }}
                                    autoWidth
                                    label=""
                                >
                                    {sortedTakerDocumentUploads.map((tdu, i) => (
                                        <MenuItem
                                            data-testid={`group-select-item-${i}`}
                                            value={tdu.id}>
                                            {tdu.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <em>create a group</em>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    xs={4}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <ToggleButtonGroup
                            data-testid="panel-view-mode"
                            size="small"
                            color="primary"
                            value={selectedPanels}
                            onChange={(_, newMode) => {
                                let hasSummaries = newMode.includes(0);
                                let hasDocuments = newMode.includes(1);
                                if (hasSummaries && hasDocuments) {
                                    mutateState<KeyTermsState>({
                                        panelViewMode: 0
                                    });
                                } else if (hasSummaries) {
                                    mutateState<KeyTermsState>({
                                        panelViewMode: 1
                                    });
                                } else if (hasDocuments) {
                                    mutateState<KeyTermsState>({
                                        panelViewMode: 2
                                    });
                                }
                            }}
                        >
                            <ToggleButton data-testid="toggle-key-terms-summaries" value={0}>Key Term Summaries</ToggleButton>
                            <ToggleButton data-testid="toggle-documents" value={1}>Documents</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item alignContent="center">
                        <Button
                            data-testid="group-manager-button"
                            variant="contained"
                            ref={groupButtonRef}
                            startIcon={<Settings />}
                            onClick={() => mutateState<KeyTermsState>({
                                groupManagerOpen: true
                            })}
                        >
                            Groups
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <GroupManagerModal
                readOnly={readOnly}
                takerDocumentId={takerDocumentId}
                open={groupManagerOpen}
                setOpen={(o) => mutateState<KeyTermsState>({
                    groupManagerOpen: o
                })}
            />
            <GenerateSummariesDialog />
        </Box>
    );
};

export default MainToolbar;