import { useSelector } from "react-redux";
import { Box, Button, Grid, IconButton, Menu, MenuItem, Checkbox, ListItemText } from "@mui/material";
import ViewComfyRoundedIcon from '@mui/icons-material/ViewComfy';
import { RootReducerType } from "../../../redux/models/reduxTypes";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { KeyTerm } from "../../../types/taker/documentkeyterms.generated";
import { useState, useEffect, useMemo } from "react";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { useSnackbar } from "notistack";
import { KeyTermDetailModalState } from "../../../containers/WidgetWrapper/states";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { CategoriesWidget } from "./CategoriesWidget";
import { CurrentInputWidget } from "./CurrentInputWidget";
import { ProposedInputWidget } from "./ProposedInputWidget";
import { HighlightDetailsWidget } from "./HighlightDetailsWidget";
import AutoScalingInput from "../../../components/form/AutoScalingInput";

interface KeyTermDetailModalProps {
    defaultKeyTerm: KeyTerm;
    open: boolean;
    onClose: () => void;
}

interface DisplayWidgetMapperProps {
    widget: KeyTermDetailModalWidgets;
    defaultKeyTerm: KeyTerm;
    handleClose: () => void;
    editedSummary?: string;
    setEditedSummary?: (s: string) => void;
    importedText: string | undefined;
    setImportedText: (s: string) => void;
    importedTextChanges: boolean;
    setImportedTextChanges: (s: boolean) => void;
}

enum KeyTermDetailModalWidgets {
    CurrentInput,
    ProposedInput,
    HighlightDetails,
    Categories
}

const buildInitialKeyTermDetailModalState = (savedState: string | null) => {
    const keyTermDetailModalState = (!!savedState && JSON.parse(savedState)) || {
        showCurrentInput: true,
        showProposedInput: true,
        showHighlightDetails: true,
        showCategories: false
    };
    return keyTermDetailModalState;
}



const DisplayWidgetMapper = ({ widget, defaultKeyTerm, handleClose, editedSummary, setEditedSummary, importedText, setImportedText, importedTextChanges, setImportedTextChanges }: DisplayWidgetMapperProps) => {
    if (widget === KeyTermDetailModalWidgets.CurrentInput) {
        return (
            <CurrentInputWidget keyTerm={defaultKeyTerm} importedText={importedText}  editedSummary={editedSummary} setEditedSummary={setEditedSummary} importedTextChanges={importedTextChanges} />
        )
    } else if (widget === KeyTermDetailModalWidgets.ProposedInput) {
        return (
            <ProposedInputWidget keyTerm={defaultKeyTerm} />
        )
    } else if (widget === KeyTermDetailModalWidgets.HighlightDetails) {
        return (
            <HighlightDetailsWidget keyTerm={defaultKeyTerm} handleClose={handleClose} setImportedText={setImportedText} importedTextChanges={importedTextChanges} setImportedTextChanges={setImportedTextChanges} />
        )
    } else if (widget === KeyTermDetailModalWidgets.Categories) {
        return (
            <CategoriesWidget keyTerm={defaultKeyTerm} />
        )

    } else {
        return (
            <div>Widget not found</div>
        )
    }
}

const ViewFormationAll = (component1: KeyTermDetailModalWidgets, component2: KeyTermDetailModalWidgets, component3: KeyTermDetailModalWidgets, component4: KeyTermDetailModalWidgets, defaultKeyTerm: KeyTerm, handleClose: () => void, editedSummary: string, setEditedSummary: (s: string) => void, importedText: string | undefined, setImportedText: (s: string) => void, importedTextChanges: boolean, setImportedTextChanges: (s: boolean) => void) => {
    return (
        <>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedText={importedText}
                    setImportedText={setImportedText} 
                    importedTextChanges={importedTextChanges}  
                    setImportedTextChanges={setImportedTextChanges}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component1}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedText={importedText}
                    setImportedText={setImportedText}
                    importedTextChanges={importedTextChanges}  
                    setImportedTextChanges={setImportedTextChanges}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component2}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedText={importedText}
                    setImportedText={setImportedText}
                    importedTextChanges={importedTextChanges}  
                    setImportedTextChanges={setImportedTextChanges}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component3}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedText={importedText}
                    setImportedText={setImportedText}
                    importedTextChanges={importedTextChanges}  
                    setImportedTextChanges={setImportedTextChanges}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component4}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
        </>
    )
}

const ViewFormation3 = (component1: KeyTermDetailModalWidgets, component2: KeyTermDetailModalWidgets, component3: KeyTermDetailModalWidgets, defaultKeyTerm: KeyTerm, handleClose: () => void, editedSummary: string, setEditedSummary: (s: string) => void, importedText: string | undefined, setImportedText: (s: string) => void, importedTextChanges: boolean, setImportedTextChanges: (s: boolean) => void) => {
    return (
        <>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedText={importedText}
                    setImportedText={setImportedText}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    importedTextChanges={importedTextChanges}  
                    setImportedTextChanges={setImportedTextChanges}
                    widget={component1}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedText={importedText}
                    setImportedText={setImportedText}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    importedTextChanges={importedTextChanges}  
                    setImportedTextChanges={setImportedTextChanges}
                    widget={component2}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={12} height="50%">
                < DisplayWidgetMapper
                    importedText={importedText}
                    setImportedText={setImportedText}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    importedTextChanges={importedTextChanges}  
                    setImportedTextChanges={setImportedTextChanges}
                    widget={component3}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
        </>
    )
}

const ViewFormation2 = (component1: KeyTermDetailModalWidgets, component2: KeyTermDetailModalWidgets, defaultKeyTerm: KeyTerm, handleClose: () => void, editedSummary: string, setEditedSummary: (s: string) => void, importedText: string | undefined, setImportedText: (s: string) => void, importedTextChanges: boolean, setImportedTextChanges: (s: boolean) => void) => {
    return (
        <>
            <Grid item xs={6} height="100%">
                <DisplayWidgetMapper
                    importedText={importedText}
                    setImportedText={setImportedText}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    importedTextChanges={importedTextChanges}  
                    setImportedTextChanges={setImportedTextChanges}
                    widget={component1}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={6} height="100%">
                <DisplayWidgetMapper
                    importedText={importedText}
                    setImportedText={setImportedText}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    importedTextChanges={importedTextChanges}  
                    setImportedTextChanges={setImportedTextChanges}
                    widget={component2}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
        </>
    )
}

const ViewFormation1 = (component1: KeyTermDetailModalWidgets, defaultKeyTerm: KeyTerm, handleClose: () => void, editedSummary: string, setEditedSummary: (s: string) => void, importedText: string | undefined, setImportedText: (s: string) => void, importedTextChanges: boolean, setImportedTextChanges: (s: boolean) => void) => {
    return (
        <>
            <Grid item xs={12} height="100%">
                <DisplayWidgetMapper
                    importedText={importedText}
                    setImportedText={setImportedText}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    importedTextChanges={importedTextChanges}  
                    setImportedTextChanges={setImportedTextChanges}
                    widget={component1}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
        </>
    )
}


const KeyTermDetailModal = ({ defaultKeyTerm, open, onClose }: KeyTermDetailModalProps) => {
    const handleCancel = () => {
        onClose();
    }
    const { takerDocumentId } = useTakerState();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [importedText, setImportedText] = useState<string>();
    const [importedTextChanges, setImportedTextChanges] = useState<boolean>(false);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const openMenu = Boolean(anchorEl);
    const { enqueueSnackbar } = useSnackbar();
    const [editedKeyTermName, setEditedKeyTermName] = useState<string>(defaultKeyTerm.termName);
    const [editedSummary, setEditedSummary] = useState(defaultKeyTerm.summary);
    const { documentKeyTermsService } = useKeyTermGroupState();
    const [widgetState, setWidgetState] = useState<KeyTermDetailModalState>(
        buildInitialKeyTermDetailModalState(localStorage.getItem(`KeyTermDetailModalState-${takerDocumentId}`)),
    );
    useEffect(() => {
        if (!!widgetState) {
            localStorage.setItem(`KeyTermDetailModalState-${takerDocumentId}`, JSON.stringify(widgetState));
        }
    }, [widgetState]);

    useEffect(() => {
        setEditedKeyTermName(defaultKeyTerm.termName);
    }, [defaultKeyTerm.termName])

    useEffect(() => {
        setEditedSummary(defaultKeyTerm.summary);
    }, [defaultKeyTerm.summary])

    useEffect(() => {
        if (importedText && importedText !== "") {
            setEditedSummary(editedSummary + " " + importedText);
        }
    }, [importedTextChanges])

    const {
        user,
    } = useSelector((state: RootReducerType) => state.auth);

    let isDebugger = useMemo(() => {
        return !!user?.roles.includes('DEBUGGER');
    }, [user]);


    let widgetsToDisplay = [];
    let viewFormation = (<div>No widgets to display</div>);
    if (widgetState.showCurrentInput) {
        widgetsToDisplay.push(KeyTermDetailModalWidgets.CurrentInput);
    }
    if (widgetState.showProposedInput) {
        widgetsToDisplay.push(KeyTermDetailModalWidgets.ProposedInput);
    }
    if (widgetState.showHighlightDetails) {
        widgetsToDisplay.push(KeyTermDetailModalWidgets.HighlightDetails);
    }
    if (widgetState.showCategories) {
        widgetsToDisplay.push(KeyTermDetailModalWidgets.Categories);
    }
    if (widgetsToDisplay.length === 4) {
        viewFormation = ViewFormationAll(widgetsToDisplay[0], widgetsToDisplay[1], widgetsToDisplay[2], widgetsToDisplay[3], defaultKeyTerm, handleCancel, editedSummary, setEditedSummary, importedText, setImportedText, importedTextChanges, setImportedTextChanges);
    } else if (widgetsToDisplay.length === 3) {
        viewFormation = ViewFormation3(widgetsToDisplay[0], widgetsToDisplay[1], widgetsToDisplay[2], defaultKeyTerm, handleCancel, editedSummary, setEditedSummary, importedText, setImportedText, importedTextChanges, setImportedTextChanges);
    } else if (widgetsToDisplay.length === 2) {
        viewFormation = ViewFormation2(widgetsToDisplay[0], widgetsToDisplay[1], defaultKeyTerm, handleCancel, editedSummary, setEditedSummary, importedText, setImportedText, importedTextChanges, setImportedTextChanges);
    } else if (widgetsToDisplay.length === 1) {
        viewFormation = ViewFormation1(widgetsToDisplay[0], defaultKeyTerm, handleCancel, editedSummary, setEditedSummary, importedText, setImportedText, importedTextChanges, setImportedTextChanges);
    }
    return (
        <SimpleModalWrapper
            headerText={`Key Term Details`}
            open={open}
            handleClose={handleCancel}
            disableBackdropClick={true}
            sx={{
                width: "90vw",
                height: "90vh",
                padding: "16px 24px 16px 24px"
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                height='calc(100% - 16px)'
                width='100%'
                paddingTop={1}
            >
                <Box
                    sx={{
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        border: "1px solid #ddd",
                        borderRadius: 1,
                        backgroundColor: "rgba(221,221,221,0.25)"
                    }}
                >
                    <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                    >
                        <Grid item xs={4} justifyContent="start" padding={1}>
                            <IconButton
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                    setAnchorEl(event.currentTarget);
                                }}
                            >
                                <ViewComfyRoundedIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem key="Current Input" >
                                    <Checkbox
                                        checked={widgetState.showCurrentInput}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setWidgetState({
                                                ...widgetState,
                                                showCurrentInput: event.target.checked
                                            });
                                        }}
                                    />
                                    <ListItemText primary="Current Input" />
                                </MenuItem>
                                <MenuItem key="Proposed Input" >
                                    <Checkbox
                                        checked={widgetState.showProposedInput}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setWidgetState({
                                                ...widgetState,
                                                showProposedInput: event.target.checked
                                            });
                                        }}
                                    />
                                    <ListItemText primary="Proposed Input" />
                                </MenuItem>
                                <MenuItem key="Highlight Details" >
                                    <Checkbox
                                        checked={widgetState.showHighlightDetails}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setWidgetState({
                                                ...widgetState,
                                                showHighlightDetails: event.target.checked
                                            });
                                        }}
                                    />
                                    <ListItemText primary="Highlight Details" />
                                </MenuItem>
                                {isDebugger && <MenuItem key="Categories" >
                                    <Checkbox
                                        checked={widgetState.showCategories}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setWidgetState({
                                                ...widgetState,
                                                showCategories: event.target.checked
                                            });
                                        }}
                                    />
                                    <ListItemText primary="Categories" />
                                </MenuItem>}
                            </Menu>
                        </Grid>
                        <Grid item xs={4} textAlign="center">
                            <AutoScalingInput
                                data-testid="key-term-name"
                                value={editedKeyTermName}
                                onChange={(newValue) => {
                                    setEditedKeyTermName(newValue);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    width='100%'
                    overflow='hidden'
                    flexGrow={1}
                    paddingTop={1}
                >
                    <Grid
                        container
                        height="calc(100% - 16px)"
                        spacing={1}
                    >
                        {viewFormation}
                    </Grid>
                </Box>
                <Box
                    width='100%'
                    paddingBottom='16px'
                    overflow='hidden'
                >
                    <Button
                        sx={{ float: "right" }}
                        data-testid="update-key-term-button"
                        variant="contained"
                        onClick={() => {
                            if (!documentKeyTermsService.containsKeyTerm(editedKeyTermName) || editedKeyTermName == defaultKeyTerm.termName) {
                                documentKeyTermsService.updateKeyTermInfoAndSummary(
                                    defaultKeyTerm.termName,
                                    editedKeyTermName,
                                    defaultKeyTerm.categories,
                                    editedSummary
                                );
                                onClose();
                            } else {
                                enqueueSnackbar(`Key Term "${editedKeyTermName}" already exists.`, { variant: "error" })
                            }
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        data-testid="cancel-key-term-button"
                        variant="outlined"
                        sx={{
                            marginLeft: "10px",
                            marginRight: "4px",
                            float: "right"
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </SimpleModalWrapper>
    );
}

export default KeyTermDetailModal;