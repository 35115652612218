import { DocumentAnnotation, DocumentKeyTerms, KeyTerm, LexicalDocument } from "./taker/documentkeyterms.generated";


export class DocumentKeyTermsHolder {
    documentKeyTerms: DocumentKeyTerms;

    constructor(documentKeyTerms: Object) {
        this.documentKeyTerms = JSON.parse(JSON.stringify(documentKeyTerms)) as DocumentKeyTerms;
    }

    createNewKeyTerm(termName: string) {
        if (!this.containsKeyTerm(termName)) {
            this.documentKeyTerms.keyTerms.push({
                identifier: window.crypto.randomUUID(),
                categories: [],
                documentAnnotations: [],
                summary: "",
                termName,
                locked: false,
                lastUpdatedSummaryAt: new Date().getTime()
            });
        }
    }

    containsKeyTerm(termName: string) {
        return this.documentKeyTerms.keyTerms.some((keyTerm: KeyTerm) => keyTerm.termName == termName);
    }

    createKeyTerm(keyTerm: KeyTerm) {
        this.documentKeyTerms.keyTerms.push(keyTerm);
    }

    removeKeyTerm(termName: string) {
        let keyTermIndex = this.documentKeyTerms.keyTerms.findIndex(kt => kt.termName === termName);
        this.documentKeyTerms.keyTerms.splice(keyTermIndex, 1);
    }

    updateKeyTermInfo(oldTermName: string, newTermName: string, newCategories: string[]) {
        for (const kt of this.documentKeyTerms.keyTerms) {
            if (oldTermName === kt.termName) {
                kt.termName = newTermName;
                kt.categories = newCategories;
                kt.lastUpdatedSummaryAt = new Date().getTime();
                if (kt.identifier === undefined) {
                    kt.identifier = window.crypto.randomUUID();
                }
            }
        }
    }

    updateKeyTermInfoAndSummary(oldTermName: string, newTermName: string, newCategories: string[], newSummary: string) {
        for (const kt of this.documentKeyTerms.keyTerms) {
            if (oldTermName === kt.termName) {
                kt.termName = newTermName;
                kt.categories = newCategories;
                kt.summary = newSummary;
                kt.lastUpdatedSummaryAt = new Date().getTime();
                if (kt.identifier === undefined) {
                    kt.identifier = window.crypto.randomUUID();
                }
            }
        }
    }

    updateKeyTermSummary(termName: string, newSummary: string) {
        for (const kt of this.documentKeyTerms.keyTerms) {
            if (termName === kt.termName) {
                kt.summary = newSummary;
                kt.lastUpdatedSummaryAt = new Date().getTime();
                if (kt.identifier === undefined) {
                    kt.identifier = window.crypto.randomUUID();
                }
            }
        }
    }

    updateKeyTermPosition(termName: string, shift: number) {
        let keyTermSourceIndex = this.documentKeyTerms.keyTerms.findIndex(kt => kt.termName === termName);
        let keyTermTargetIndex = keyTermSourceIndex + shift;

        let temp = this.documentKeyTerms.keyTerms[keyTermSourceIndex];
        this.documentKeyTerms.keyTerms[keyTermSourceIndex] = this.documentKeyTerms.keyTerms[keyTermTargetIndex];
        this.documentKeyTerms.keyTerms[keyTermTargetIndex] = temp;
    }

    toggleKeyTermLocked(termName: string) {
        for (const kt of this.documentKeyTerms.keyTerms) {
            if (termName === kt.termName) {
                kt.locked = !kt.locked;
            }
        }
    }

    addDocumentAnnotation(termNames: string[], docAnnotation: DocumentAnnotation) {
        let termNamesCpy = new Set(termNames);
        for (const kt of this.documentKeyTerms.keyTerms) {
            if (termNames.includes(kt.termName)) {
                kt.documentAnnotations?.push({ ...docAnnotation });
                kt.lastUpdatedDocumentReferencesAt = new Date().getTime();
                termNamesCpy.delete(kt.termName);
            }
        }

        // Add new terms
        for (const newTermName of termNamesCpy) {
            this.documentKeyTerms.keyTerms.push({
                identifier: window.crypto.randomUUID(),
                categories: [],
                documentAnnotations: [{ ...docAnnotation }],
                summary: "",
                termName: newTermName,
                locked: false,
                lastUpdatedSummaryAt: new Date().getTime()
            });
        }
    }

    removeDocumentAnnotation(termName: string, documentAnnotation: DocumentAnnotation) {
        for (const kt of this.documentKeyTerms.keyTerms) {
            if (termName === kt.termName && kt.documentAnnotations) {
                kt.documentAnnotations = kt.documentAnnotations.filter(
                    (da) => da.annotationId !== documentAnnotation.annotationId
                );
                kt.lastUpdatedSummaryAt = new Date().getTime();
            }
        }
    }

    createLexicalDocument(ld: LexicalDocument) {
        if (this.documentKeyTerms.lexicalDocuments) {
            this.documentKeyTerms.lexicalDocuments.push(ld);
        }
    }

    updateLexicalDocumentPage(documentIdentifier: string, page: number, lexical: Object) {
        if (this.documentKeyTerms.lexicalDocuments) {
            for (const ld of this.documentKeyTerms.lexicalDocuments) {
                if (ld.identifier === documentIdentifier) {
                    ld.lexicalPages[page] = lexical;
                }
            }
        }
    }

    removeLexicalDocument(documentIdentifier: string) {
        if (this.documentKeyTerms.lexicalDocuments) {
            // Remove all annotations associated with document
            for (const kt of this.documentKeyTerms.keyTerms) {
                if (kt.documentAnnotations) {
                    kt.documentAnnotations = kt.documentAnnotations.filter(
                        (docAnnotation) => docAnnotation.lexicalDocumentIdentifier !== documentIdentifier
                    );
                }
            }

            // Remove the whole document.
            let documentIndex = this.documentKeyTerms.lexicalDocuments.findIndex(ld => ld.identifier === documentIdentifier);
            this.documentKeyTerms.lexicalDocuments.splice(documentIndex, 1);
        }
    }

    buildIndexedDocumentAnnotations() {
        const indexed: Record<string, Record<number, DocumentAnnotation[]>> = {};
        for (const kt of this.documentKeyTerms.keyTerms) {
            if (kt.documentAnnotations) {
                for (const da of kt.documentAnnotations) {
                    if (!indexed[da.lexicalDocumentIdentifier]) {
                        indexed[da.lexicalDocumentIdentifier] = {};
                    }
                    if (!indexed[da.lexicalDocumentIdentifier][da.page]) {
                        indexed[da.lexicalDocumentIdentifier][da.page] = [];
                    }
                    indexed[da.lexicalDocumentIdentifier][da.page].push(da);
                }
            }
        }
        return indexed;
    }
}