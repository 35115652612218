import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Archive, PlayArrow } from "@mui/icons-material";
import { useDeleteTakerMutation, useListTakersQuery } from "../../redux/services/taker";
import { Taker } from "../../redux/models/dataModelTypes";
import PagedTable from "../../components/tables/pagedTable";
import { CrudButtonGroup } from "../../components/buttons/crudButtonGroup";
import TakerDocumentStatus from "./TakerDocumentStatus";

const latestTakerDocumentUpdated = (taker: Taker) => {
  let takerDocuments = taker.takerDocuments;
  if (takerDocuments[0]) {
    return new Date(takerDocuments[0].updatedAt).toLocaleString();
  } 
  return "unknown";
}

interface Props {
  builderId: string;
}

const MyRecentTakers = ({ builderId }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(5);

  const navigate = useNavigate();

  const {
    data: takers,
    isFetching,
    isLoading,
  } = useListTakersQuery({ page, limit, builderId });

  const [deleteTaker, deleteTakerResult] = useDeleteTakerMutation();

  return (
    <PagedTable
      isFetching={isFetching}
      isLoading={isLoading}
      paginatedResponse={takers}
      tableSpan={5}
      rowMapper={(t: Taker) => (
        <TableRow
          key={t.id}
        >
          <TableCell>
            {t.name ? (
              t.name
            ) : (
              "no name"
            )}
          </TableCell>
          <TableCell>
            <TakerDocumentStatus takerDocument={t.takerDocuments[0]}/>
          </TableCell>
          <TableCell>
            {latestTakerDocumentUpdated(t)}
          </TableCell>
          <TableCell>
            {new Date(t.createdAt).toLocaleString()}
          </TableCell>
          <TableCell>
            <CrudButtonGroup
              buttons={[
                {
                  icon: (<PlayArrow fontSize="small" />),
                  handleClick: () => {
                    navigate(`/mainTaker/${t.id}/keyTerms`);
                  }
                },
                {
                  icon: (
                    <Archive fontSize="small" />
                  ),
                  handleClick: () => {
                    deleteTaker(t.id);
                  }
                }
              ]}
            />
          </TableCell>
        </TableRow>
      )}
      headers={[
        "Name",
        "Status",
        "Last Updated",
        "Created",
        ""
      ]}
      page={page}
      limit={limit}
      setPage={setPage}
      setLimit={setLimit}
    />
  );
}

export default MyRecentTakers;
