import { Button, IconButton } from "@mui/material";
import { useState } from "react";
import { Delete } from "@mui/icons-material";
import ConfirmationDialog from "../dialog/GenericConfirmation";

interface DeleteWithConfirmationButtonProps {
    onDelete: () => void;
    confirmationText: string;
    icon?: JSX.Element;
    useDefaultButtonStyle?: boolean;
    defaultButtonText?: string;
}

const DeleteWithConfirmationButton = ({
    onDelete,
    confirmationText,
    icon,
    useDefaultButtonStyle,
    defaultButtonText
}: DeleteWithConfirmationButtonProps) => {
    const [needConfirm, setNeedConfirm] = useState<boolean>(false);

    const deleteClicked = () => {
        setNeedConfirm(true);
    }

    return (
        <>
            {useDefaultButtonStyle != null && useDefaultButtonStyle == true ? 
                (<>
                    <Button
                        data-testid="DeleteWithConfirmationButtonDefaultStyle"
                        color="error"
                        variant="contained"
                        sx={{ marginLeft: "10px" }}
                        onClick={() => {
                            deleteClicked();
                        }}
                    >
                        {defaultButtonText ? defaultButtonText: "Delete"}
                    </Button>
                </>) : 
                (
                    <IconButton
                        data-testid="DeleteWithConfirmationButton"
                        onClick={() => {
                            deleteClicked();
                        }}
                    >
                        {

                            icon ? (<>{icon}</>):  (<Delete data-testid="DeleteIcon"/>)
                        }
                    </IconButton>
                )
            }
            {needConfirm && (
                <ConfirmationDialog
                    title="Confirmation"
                    confirmationText={confirmationText}
                    onConfirmed={() => {
                        onDelete();
                        setNeedConfirm(false);
                    }}
                    onCancelled={() => {
                        setNeedConfirm(false);
                    }}
                />
            )}
        </>
    );
};

export default DeleteWithConfirmationButton;