import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
    Box,
    Button,
} from "@mui/material/";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { useReadOnlyBuilderData } from "../../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData";
import { useAddTakerDocumentDataMutation } from "../../../redux/services/takerData";
import ModuleFulfillment from "./ModuleFulfillment";
import { generateEmptyUIDataSchema } from "../../../types/taker";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { PageElementIdentifier, AnalysisState } from "../../../containers/WidgetWrapper/states";


const Quesionnaire = () => {
    const {
        takerDocumentId,
        taker,
        isTakerDocumentLoading,
        takerPermissionState,
        activeTargetTakerState,
        latestQuestionnaireDataId,
        isQuestionnaireUninitialized
    } = useTakerState();
    const { builderHolder } = useReadOnlyBuilderData();
    const {
        getState,
        mutateState
    } = useWidgetState();

    const [addTakerDocumentData, addTakerDocumentDataRes] = useAddTakerDocumentDataMutation();

    const isReadOnly = useMemo(() =>
        takerPermissionState.isRead && !takerPermissionState.isReadWrite,
        [takerPermissionState]
    );

    const topModule = builderHolder.builder.topModule;

    const visibleElement = useRef<PageElementIdentifier>();
    const delayedSelectedElementUpdate = useRef<any>();

    const [localSelectedElement, setLocalSelectedElement] = useState<PageElementIdentifier>();

    const containerRef = useRef<HTMLDivElement>();
    const refs = useRef<
        Record<
            string,
            {
                elem: HTMLDivElement;
                fullIdentifier: PageElementIdentifier
            }
        >>({});

    const selectedQuestionnaireElement = getState<AnalysisState>().selectedElement;
    const collapsedState = getState<AnalysisState>().collapsedState;

    const checkInView = () => {
        if (containerRef.current && containerRef.current.parentElement) {
            const containerParentRect = containerRef.current.parentElement.getBoundingClientRect();
            for (const { elem, fullIdentifier } of Object.values(refs.current)) {
                const rect = elem.getBoundingClientRect();
                let inView = rect.top >= containerParentRect.top && rect.bottom <= containerParentRect.bottom;
                if (inView) {
                    visibleElement.current = {
                        moduleIds: fullIdentifier.moduleIds,
                        iteration: fullIdentifier.iteration,
                        nodeId: fullIdentifier.nodeId
                    };

                    if (delayedSelectedElementUpdate.current) {
                        clearTimeout(delayedSelectedElementUpdate.current);
                    }
                    delayedSelectedElementUpdate.current = setTimeout(() => {
                        setLocalSelectedElement({
                            moduleIds: fullIdentifier.moduleIds,
                            iteration: fullIdentifier.iteration,
                            nodeId: fullIdentifier.nodeId
                        });
                    }, 50);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        if (!!localSelectedElement) {
            mutateState<AnalysisState>({
                selectedElement: {
                    moduleIds: localSelectedElement.moduleIds,
                    iteration: localSelectedElement.iteration,
                    nodeId: localSelectedElement.nodeId
                }
            });
        }
    }, [localSelectedElement]);

    const setScrollableRefs = (
        identifier: {
            moduleIds: string[];
            iteration: null | number;
            nodeId?: string;
        },
        ref: HTMLDivElement
    ) => {
        let isTopModule = identifier.moduleIds.length === 1 && identifier.iteration === null && !identifier.nodeId;
        if (isTopModule) {
            return;
        }

        let stringifiedId = `${identifier.moduleIds.join('')}-${identifier.iteration}-${identifier.nodeId}`;
        refs.current[stringifiedId] = {
            elem: ref,
            fullIdentifier: {
                moduleIds: identifier.moduleIds,
                iteration: identifier.iteration,
                nodeId: identifier.nodeId
            }
        };
    };

    let refsNumber = Object.keys(refs.current);

    // Initialize our visibleElement
    useEffect(() => {
        if (visibleElement.current === undefined && selectedQuestionnaireElement !== undefined) {
            let stringifiedId = `${selectedQuestionnaireElement.moduleIds.join('')}-${selectedQuestionnaireElement.iteration}-${selectedQuestionnaireElement.nodeId}`;
            let myRef = refs.current[stringifiedId];
            if (myRef) {
                myRef.elem.scrollIntoView({ block: 'center' });
                visibleElement.current = {
                    moduleIds: selectedQuestionnaireElement.moduleIds,
                    iteration: selectedQuestionnaireElement.iteration,
                    nodeId: selectedQuestionnaireElement.nodeId
                };
            }
        }
    }, [selectedQuestionnaireElement, refsNumber]);

    useEffect(() => {
        checkInView();
        if (containerRef.current && containerRef.current.parentElement) {
            containerRef.current.parentElement.addEventListener("scroll", checkInView);
        }
        return () => {
            if (containerRef.current && containerRef.current.parentElement) {
                containerRef.current.parentElement.removeEventListener("scroll", checkInView);
            }
        };
    }, [containerRef, containerRef.current?.parentElement]);

    useEffect(() => {
        if (containerRef.current && containerRef.current.parentElement) {
            checkInView();
        }
    }, [collapsedState && JSON.stringify(collapsedState)]);

    // The automation doesn't have a top level module which is required
    if (!topModule) {
        return (
            <span>automation is misconfigured</span>
        );

    // The document is loading, or the active state has not been set
    } else if (
        isTakerDocumentLoading && 
        !activeTargetTakerState
    ) {
        return (
            <span>loading</span>
        );
    }

    const InitButtonComponent = memo(() => (
        <Box
            sx={{ backgroundColor: "white" }}
            border="1px solid rgb(195, 195, 195)"
            borderRadius={1}
            padding="5%"
            height="calc(100% - 5px)"
            display="block"
            alignItems="center"
            alignContent="center"
            textAlign="center"
        >
            <Button
                disabled={isReadOnly}
                onClick={() => {
                    if (!isReadOnly) {
                        if (taker && topModule) {
                            addTakerDocumentData({
                                takerId: taker.id,
                                takerDocumentId: takerDocumentId,
                                contentType: "QUESIONNAIRE",
                                content: generateEmptyUIDataSchema(topModule)
                            });
                        }
                    }
                }}
            >
                Start Questionnaire
            </Button>
        </Box>
    ));

    // The document has loaded, questionnaire data has initialized, but is empty.
    if (!isTakerDocumentLoading 
        && !isQuestionnaireUninitialized 
        && !latestQuestionnaireDataId
    ) {
        return (
            <Box
                sx={{
                    width: "100%",
                    overflowY: "auto",
                    height: "100%"
                }}
            >
                <InitButtonComponent />
            </Box>
        );
    }

    // We are able to display the questionnaire and it's data.
    return (
        <Box
            sx={{
                width: "100%",
                overflowY: "auto",
                height: "100%"
            }}
        >
            <div
                id="questionnaire-container"
                ref={(ref) => {
                    if (ref) {
                        containerRef.current = ref;
                    }
                }}
            >
                <ModuleFulfillment
                    modulePrefixes={[]}
                    module={topModule}
                    iteration={null}
                    visible={true}
                    setScrollableRefs={setScrollableRefs}
                    setSelectedElement={(peid) => {
                        visibleElement.current = peid;
                        if (delayedSelectedElementUpdate.current) {
                            clearTimeout(delayedSelectedElementUpdate.current);
                        }
                        setLocalSelectedElement(peid);
                    }}
                />
            </div>
        </Box>
    );
}

export default Quesionnaire;