import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { PageItem } from "../../data/pages";
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { signOut } from "../../redux/actionCreators/authActionCreators";
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import { useDispatch } from "../../redux/reduxUtils/functions";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { pageOption } from "../../data/pages";
import { Avatar, Menu, MenuItem, Select, Typography } from "@mui/material";
import { useUserScopedAppData } from "../../containers/UserScopedAppData/UserScopedAppData";
import { User } from "../../redux/models/dataModelTypes";
import logo from "./../../assets/images/logo_icon_300Px.png";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(5)} + 1px)`,
    [theme.breakpoints.up('xs')]: {
        width: `calc(${theme.spacing(6)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1)
}));

function stringAvatar(user: User) {
    let name = `${user.firstName} ${user.lastName}`;
    return {
        sx: {
            bgcolor: "rgb(25, 118, 210)",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<MuiAppBarProps & { open?: boolean }>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Toolbar = styled(MuiToolbar)`
    width: 100%;
    padding-left: 16px !important;
    padding-right: 16px !important;
`;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface SideNavProps { }

const renderOrgValue = (user: User, selected: string) => {
    if (!selected) {
        return "My Sandbox";
    }
    for (const om of user.organizationMemberships) {
        if (selected === om.organization.id) {
            return om.organization.name;
        }
    }
    return "unknown";
};

const SideNav: React.FC<PropsWithChildren<SideNavProps>> = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [menuItems, setMenuItems] = React.useState<PageItem[]>([]);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const { selectedOrgId, setSelectedOrgId } = useUserScopedAppData();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const {
        authenticated,
        user,
        isLoading
    } = useSelector((state: RootReducerType) => state.auth);

    useEffect(() => {
        let menuItems: PageItem[] = [];
        if (user) {
            if (user.roles.includes("BUILDER") || user.roles.includes("TAKER")) {
                menuItems.push(pageOption.HOME);
            }
            if (user.roles.includes("TECHNICAL")) {
                menuItems.push(pageOption.MANAGE_MODELS,);
            }
            if (user.roles.includes("SUPERADMIN")) {
                menuItems.push(pageOption.MANAGE_APP_CONFIGS);
            }
            if (user.roles.includes("ADMIN")) {
                menuItems.push(pageOption.MANAGE_USERS);
                menuItems.push(pageOption.MANAGE_ORGANIZATIONS);
            }
            if (user.roles.includes("INDEXER")) {
                menuItems.push(pageOption.INDEXED_GUIDANCE_LIST);
            }
        }
        setMenuItems(menuItems);
    }, [user]);

    let isDebugger = useMemo(() => {
        return !!user?.roles.includes('DEBUGGER');
    }, [user]);

    if (!user || isLoading || !authenticated) {
        return null
    }

    return (
        <Box sx={{ display: 'flex', height: "100vh" }}>
            <AppBar
                position="absolute"
                color="transparent"
                elevation={0}
                open={open}
            >
                <Toolbar variant="dense">
                    <IconButton
                        id="menuTogglingIconButton"
                        data-testid="menu-toggling-icon"
                        onClick={toggleDrawer}
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        sx={{
                            marginRight: 2,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        {(open ?
                            <ChevronLeftIcon id="ChevronIconButton" /> :
                            <MenuIcon id="MenuIconButton" />
                        )}
                    </IconButton>
                    <Box 
                        sx={{ 
                            flexGrow: 1,
                            alignItems: "center",
                            display: "flex"
                        }}
                    >
                        <Box 
                            component="img" 
                            src={logo} 
                            alt="Praxi" 
                            sx={{ height: "24px" }}
                        />
                        <Typography 
                            variant="h5"
                            sx={{ paddingLeft: "6px" }}
                        >
                            Praxi
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton id="handleOpenUserMenu" size="small" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar {...stringAvatar(user)} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem
                                id="userSettingsMenuItem"
                                key={pageOption.USER_SETTINGS.name}
                                onClick={() => {
                                    handleCloseUserMenu();
                                    navigate(pageOption.USER_SETTINGS.route);
                                }}
                            >
                                <Typography textAlign="center">
                                    User Settings
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                id="handleLogoutMenuItem"
                                onClick={() => {
                                    handleCloseUserMenu();
                                    dispatch(signOut());
                                }}
                            >
                                <Typography textAlign="center">
                                    Logout
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader sx={{ height: "48px", minHeight: "48px" }}>
                    {open && (
                        <IconButton id="toggleDrawer" onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    )}
                </DrawerHeader>
                <Divider />
                <List>
                    {menuItems.map((pi: PageItem) => (
                        pi.name === "" ? (
                            <Divider />
                        ) : (
                            <ListItem key={pi.name} disablePadding sx={{ display: 'block' }} data-testid={`list-item-${pi.name}`}>
                                <ListItemButton
                                    data-testid={pi.name}
                                    onClick={() =>
                                        //If they click log out then log them out. Do not navigate.
                                        pi.route !== "/" ? navigate(pi.route) : dispatch(signOut())
                                    }
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <pi.icon />
                                    </ListItemIcon>
                                    <ListItemText primary={pi.name} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        )
                    ))}
                </List>
                {open && (
                    <Box height="100%" display="flex">
                        <Box 
                            display="inline-block"
                            alignSelf="end"
                            padding={2}
                            textAlign="center"
                            width="100%"
                        >
                            {isDebugger ? (
                                <Select
                                    size="small"
                                    sx={{ minWidth: "200px" }}
                                    displayEmpty
                                    label=""
                                    defaultValue={selectedOrgId}
                                    renderValue={(selected) => 
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            <strong>
                                                {renderOrgValue(user, selected)}
                                            </strong>
                                        </Box>
                                    }
                                    onChange={(e: any) => {
                                        setSelectedOrgId(e.target.value);
                                    }}>
                                    <MenuItem value="">
                                        Sandbox
                                    </MenuItem>
                                    {user.organizationMemberships.map((om) =>
                                        <MenuItem value={om.organization.id}>
                                            {om.organization.name}
                                        </MenuItem>
                                    )}
                                </Select>
                            ) : (
                                <Typography variant="h6">
                                    {renderOrgValue(user, selectedOrgId)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                )}
                
                
            </Drawer>
            <Box sx={{ flexGrow: 1 }}>
                <DrawerHeader sx={{ height: "48px" }} />
                <Box component="main" sx={{ height: "calc( 100% - 48px );" }}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}

export default SideNav;